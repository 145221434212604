.experience-section {
    width: 100%;
    background: linear-gradient(#1c1c1c, #00082c);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
}

.experience-section h1 {
    font-size: 5.5rem; /* Adjust the font size as needed */
}

.timeline-section {
    /* display: grid; */
    width: 90%;
    /* grid-template-columns: 1fr 1fr; */
    height: fit-content;

    background: #343A40;
    /* -webkit-box-shadow: 5px 5px 10px #060606, -5px -5px 10px #060606;
            box-shadow: 5px 5px 10px #060606, -5px -5px 10px #000c47; */
    border-radius: 2px;
    color: white;
    transition: 0.4s ease;
    overflow-wrap: break-word !important;
    margin: 2rem;
    margin-bottom: 20px;
    padding: 20px;
}

.timeline-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30%;
    float: left;
}

.company-logo {
    max-height: 50%;
    max-width: 80%;
    text-align: center;
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center;
    padding-top: 50px;
}

.timeline-heading h2 {
    font-size: 2rem; /* Adjust the font size as needed */
    text-align: center;
    display: flex; /* Use flexbox */
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    height: 50%; /* Ensure the height fills the container */
}

.timeline-content {
    background: #343A40;
    /* -webkit-box-shadow: 5px 5px 10px #060606, -5px -5px 10px #060606;
            box-shadow: 5px 5px 10px #060606, -5px -5px 10px #000c47; */
    border-radius: 5px;
    color: #b8c4cf;
    transition: 0.4s ease;
    overflow-wrap: break-word !important;
    margin: 2rem;
    margin-bottom: 20px;
    /* padding: 20px; */
    width: 60%;
    float: left;
}

.timeline-content h2 {
    font-size: 2rem;
}

.timeline-content h3 {
    font-size: 1.5rem; /* Adjust the font size as needed */
}

.timeline-content p{
    text-indent: 50px;
    font-size: 1.2rem;
}

.timeline-content li{
    font-size: 1.2rem;
    line-height: 1.6rem;
}
