.projects-section {
    width: 100%;
    background: linear-gradient(#00082c, #16213E);
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;

}

.projects-section h1 {
    font-size: 5.5rem;
}

.project-card-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color:rgb(59, 102, 230);
}

.project-card-section h1{
    font-size: 3.5rem; /* Adjust the font size as needed */
    text-align: center;
    display: flex; /* Use flexbox */
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center the content vertically */
    height: 100%;
}

.project-card-title {
    margin: 0px 40px 40px 40px;
    color: #3A86FF;
}

.project-card-description {
    background: #343A40;
    -webkit-box-shadow: 5px 5px 10px #060606, -5px -5px 10px #060606;
            box-shadow: 5px 5px 10px #060606, -5px -5px 10px #000c47;
    border-radius: 5px;
    color: white;
    transition: 0.4s ease;
    overflow-wrap: break-word !important;
    margin: 2rem;
    margin-bottom: 20px;
    padding: 20px;
}

.project-card-subtitle {
    /* background-color: #ADB5BD; */
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}

.project-card-info {
    background-color: #6C757D;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
}


.read-more-button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.read-more-button:hover {
    background-color: #0056b3;
}

.project-card-description {
    overflow: visible; /* Ensure description is always visible */
}

.project-card-info {
    color: #ffffff; /* Default color */
}

.project-card-info.expanded p {
    color: rgb(215, 215, 215); /* Change description text color to grey when expanded */
}

.expanded-content {
    margin-top: 10px;
    font-size: 0.9em;
    color: white; /* Article content color */
    background-color: #333; /* Optional: Set background for better contrast */
    padding: 10px;
    border-radius: 5px;
}
