.main {
    background-image: url('../assets/NYC-Background.png');
    background-size: cover;
    /* height: auto; */
    height: 100vh;
    /* width: 100%; */
    color: white;
    /* background-color: rgb(10, 10, 10); */
}

.column {
    float: left;
    width: 50%;
    margin-top: 8vh;
}

.full-image {
    width: 100vw;
    height: 92vh;
    z-index: 1;
    position: relative;
    margin-top: 8vh;
    margin-left: -50vw;
}

.background-darkening {
    position: absolute;
    top: 8vh;
    left: 50%;
    height: 92vh;
    width: 50vw;
    background: black;
    z-index: 2;
    opacity: 0.7;
    /* margin-left: -50vw; */
}

.main-text {
    display: flex;
    position: relative;
    left: 10%;
    flex-direction: column;
    justify-content: center;
    height: 92vh;
    width: 80%;
    z-index: 2;
    color: white;
}

.main-text h1{
    font-size: 50px;
    margin-bottom: 0px;
    text-align: center;
}

.main-text p{
    line-height: 42px;
    font-size: 25px;
    text-indent: 50px;
}