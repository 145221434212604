.card-view {
    font-family: 'Georgia, serif';
    background-color: #ffffff; /* Ensure this is applied correctly */
    max-width: 1500px;
    min-width: 1000px;
    width: 50%;
    margin: 50px;
    text-decoration: none;
    color: inherit;
    border: thick;
    transition: box-shadow 0.3s;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 5px 5px 20px;
}

.card-view:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.card-image {
    width: 100%;
    height: auto;
}

.card-body {
    background-color: #ffffff; /* Explicitly set the background color for the body too if necessary */
    padding-left: 20px;
    padding-right: 20px;
}

.card-title {
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
}

.card-date {
    font-size: 0.9rem;
    color: #666;
}
