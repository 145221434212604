#top-bar {
    height: 8vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    background-color: rgb(10, 10, 10);

    position: fixed; /* Make the navbar fixed */
    top: 0; /* Stick it to the top */
    z-index: 1000; /* Ensure the navbar is above other content */
}

.top-bar-item {
    color:aliceblue;
    width: 10%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 100%;
}

.top-bar-item:hover {
    color:#3A86FF;
    width: 10%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: larger;
    border-bottom:thick double #3A86FF;
    font-weight:bold;
    /* opacity: 10%; */
}