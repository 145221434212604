.blog-section {
    min-height: 100vh;
    width: 100%;
    background: linear-gradient(#16213E, #000000);
    /* background: linear-gradient(rgb(0,0,0), #1c1c1c); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1 {
    font-size: 5.5rem;
    color: white;
}