.contact-section {
    width: 100%;
    /* background: linear-gradient(#16213E, #000000); */
    background: linear-gradient(rgb(0,0,0), #1c1c1c);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 100px;
}

.contact-section h1 {
    font-size: 5.5rem;
}

.contact-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
}

.contact-details {
    margin-top: 20px;
}

.contact-details p {
    margin-bottom: 10px;
}

.contact-details a {
    text-decoration: none;
    color: #007bff; /* Link color */
}
