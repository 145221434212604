.main-about {
    min-height: 100vh;
    /* height: fit-content; */
    width: 100%;
    /* background: linear-gradient(rgb(28, 28, 28), #1c1c1c); */
    background: linear-gradient(rgb(10, 10, 10), #1c1c1c);
    color: white;
    overflow: auto;
    /* position: relative; */
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.main-about h1 {
    text-align: center;
    font-size: 5.5rem;
    padding-top: 10vh;
}

.about-column {
    float: left;
    width: 40%;
    padding-top: 8vh;
    padding-left: 5%;
    padding-right: 5%;
}

.about-column p{
    font-size: 27px;
    line-height: 40px;
    text-indent: 50px;
}

.resume-button-container {
    margin-top: 20px;
    text-align: center;
}

.resume-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007BFF;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease;
}

.resume-button:hover {
    background-color: #0056b3;
}
